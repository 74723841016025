/**
* Generated automatically at built-time (2025-01-24T10:29:45.724Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-inox-range",templateKey: "sites/99-b8017da1-f341-402c-9b83-f0290cf4648c"};