/**
* Generated automatically at built-time (2025-01-24T10:29:45.751Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-stainless-collection",templateKey: "sites/99-d383aead-41f6-4606-a91d-2a1194fc6a3c"};